import Vue from "vue";
import App from "./App.vue";

import VHover from "v-hover";
Vue.use(VHover);

// ROUTES
import { routes } from "./router";

import VueRouter from "vue-router";
Vue.use(VueRouter);

// INITIALIZE VUE ROUTER
let base = "";
if (process.env.NODE_ENV === "development") {
  base = "/";
} else {
  base = "/projects/softt365/";
}
const router = new VueRouter({
  routes: routes,
  mode: "history", //removes # (hashtag) from url
  base,
  fallback: true,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
});

router.mode = "html5";

import header from "./components/Header.vue";
import footer from "./components/Footer.vue";
import sidebar from "./components/Sidebar.vue";

Vue.config.productionTip = false;

Vue.component("Header", header);
Vue.component("Footer", footer);
Vue.component("Sidebar", sidebar);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
