<template>
  <div>
    <Sidebar></Sidebar>
    <Header></Header>
    <div class="container-fluid padding-top-70">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row margin-top-120">
            <div class="col-md-12">
              <p
                class="
                  font-size-42
                  text-align-center
                  CamptonBold
                  text-transform
                  line-height-50
                "
              >
                Nos valeurs
              </p>
              <p>&nbsp;</p>
            </div>
            <div class="col-md-12">
              <div class="row align-items-center">
                <div class="col-md-6 show-in-desktop">
                  <p class="text-align-center">
                    <img
                      src="assets/images/qualite.svg"
                      class="width-100"
                      alt="La qualité"
                    />
                  </p>
                </div>
                <div class="col-md-6">
                  <div class="default-border mobile-margin-bottom-25">
                    <p class="CamptonBold text-transform">Qualité</p>
                    <p class="show-in-mobile text-align-center">
                      <img
                        src="assets/images/qualite.svg"
                        class="width-100"
                        alt="La qualité"
                      />
                    </p>
                    <p>
                      La compétence de nos équipes alliée à la rigueur de nos
                      processus et notre engagement à délivrer le meilleur
                      garantissent à nos clients un produit optimal,
                      parfaitement sécurisé et parfaitement conforme à leurs
                      exigences.
                      <br />
                      L’écoute et la disponibilité de nos équipes garantissent à
                      nos clients une qualité de service irréprochable qui nous
                      vaut la pleine satisfaction et la fidélité de nos
                      partenaires actuels et qui fait la différence au
                      quotidien!
                      <br />
                      Nous travaillons au quotidien pour établir un système de
                      gestion de la qualité efficace qui garantit la
                      satisfaction de toutes les parties prenantes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="default-border mobile-margin-bottom-25">
                    <p class="CamptonBold text-transform">Passion</p>
                    <p class="show-in-mobile text-align-center">
                      <img
                        src="assets/images/passion.svg"
                        class="width-100"
                        alt="La passion"
                      />
                    </p>
                    <p>
                      La Passion est le moteur qui nous anime au quotidien :
                      <br />
                      Nous sommes passionnés par la Technologie pour ce qu'elle
                      peut apporter au quotidien à nos clients et aux clients de
                      nos clients.
                      <br />
                      Nous sommes passionnés par l'Innovation, vecteur de
                      différenciation, de productivité, de croissance et de
                      rentabilité pour notre entreprise et celles de nos
                      partenaires.
                      <br />
                      Nous sommes passionnés par l'Humain: nos clients, nos
                      collaborateurs, nos partenaires et les communautés dans
                      lesquelles nous évoluons.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 show-in-desktop">
                  <p class="text-align-center">
                    <img
                      src="assets/images/passion.svg"
                      class="width-100"
                      alt="La passion"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 margin-top-50">
              <div class="row align-items-center">
                <div class="col-md-6 show-in-desktop">
                  <p class="text-align-center">
                    <img
                      src="assets/images/agilite.svg"
                      class="width-100"
                      alt="L'agilité"
                    />
                  </p>
                </div>
                <div class="col-md-6">
                  <div class="default-border mobile-margin-bottom-25">
                    <p class="CamptonBold text-transform">Agilité</p>
                    <p class="show-in-mobile text-align-center">
                      <img
                        src="assets/images/agilite.svg"
                        class="width-100"
                        alt="L'agilité"
                      />
                    </p>
                    <p id="agility">
                      Nous sommes une petit structure flexible, à l'écoute de
                      nos clients et prêts à adapter nos offres et nos modalités
                      de collaboration en fonction de leurs besoins et leurs
                      contraintes.
                      <br />
                      Nous sommes à l'écoute de nos collaborateurs et engagés
                      dans un dialogue permanent pour optimiser ensemble notre
                      organisation interne.
                      <br />
                      Nous adaptons nos moyens et nos ressources pour offrir à
                      nos clients le meilleur produit et un full service
                      personnalisé tout au long de notre collaboration.
                      <br />
                      Nous adaptons nos moyens et nos ressources pour offrir à
                      nos collaborateurs le meilleur environnement de travail
                      possible. Un collaborateur satisfait est un collaborateur
                      qui produit le meilleur.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- <div class="container-fluid padding-top-100 padding-bottom-100 background-grise">
      <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
              <div class="row align-items-center">
                  <div class="col-md-6">
                      <div class="padding-left-25">
                          <p class="margin-top-20 font-size-42 CamptonBold text-transform line-height-50">Notre équipe</p>
                          <p class="show-in-mobile">&nbsp;</p>
                          <div class="show-in-mobile text-align-center mobile-margin-bottom-25"><img src="assets/images/small-teams.png" class="max-height-400 width-100" alt="" /></div>
                          <p class="show-in-mobile">&nbsp;</p>
                          <p>
                            Notre richesse c'est notre équipe.
                            <br />
                            Elle se distingue par sa disponibilité, sa polyvalence ( équipe multi- sectorielle et multi-technologies) et son engagement.
                            <br />
                            Logique, esprit d’analyse, curiosité, patience, rigueur et sens du service, telles sont les qualités que notre système de management  cultive au quotidien.
                            <br />
                            Chez <strong>SOFTT365</strong> , nous sommes attachés à créer un environnement de travail qualitatif et sain au service du bien-être et du développement constant de nos équipes car nous croyons que seul un employé satisfait peut fournir un travail de qualité.
                            <br />
                            Les membres de notre équipe sont liés par un fil invisible qui se matérialise par la Passion pour la Technologie et l'Amour du travail bien fait.
                            <br />
                            La diversité de notre équipe (métiers, âges, personnalités) nous permet d’apporter une valeur unique qui nous aide à stimuler l’innovation et à satisfaire nos clients.
                          </p>
                          <p class="show-in-mobile">&nbsp;</p>
                      </div>
                  </div>
                  <div class="col-md-6 text-align-center show-in-desktop">
                      <img src="assets/images/team.svg" class="max-height-550 width-100" alt="" />
                  </div>
              </div>
          </div>
          <div class="col-md-1"></div>
      </div>
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: "Company",
  mounted() {
    window.scrollTo(0, 0);
    document.title = "SOFTT365 : Entreprise";
  },
  components: {},
};
</script>
