var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid top-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row header"},[_c('div',{staticClass:"col-md-3 col-6"},[_c('router-link',{staticClass:"CamptonBook",attrs:{"to":{ name: 'Home' },"title":"Accueil"}},[(
                _vm.$route.name == 'Company' ||
                _vm.$route.name == 'Conditions-generales'
              )?_c('span',[_c('img',{attrs:{"src":"assets/images/logo-other-page.svg","alt":"Logo SOFTT 365"}})]):_vm._e(),(
                _vm.$route.name != 'Company' &&
                _vm.$route.name != 'Conditions-generales'
              )?_c('span',[_c('img',{attrs:{"src":"assets/images/logo.svg","alt":"Logo SOFTT 365"}})]):_vm._e()])],1),_c('div',{staticClass:"col-6 show-in-mobile text-align-right"},[_c('span',{staticClass:"sidebar-nav cursor-pointer",attrs:{"onclick":"openNav()"}},[(
                _vm.$route.name == 'Company' ||
                _vm.$route.name == 'Conditions-generales'
              )?_c('span',[_c('img',{attrs:{"src":"assets/images/menu.svg","alt":"Société"}})]):_vm._e(),(
                _vm.$route.name != 'Company' &&
                _vm.$route.name != 'Conditions-generales'
              )?_c('span',[_c('img',{attrs:{"src":"assets/images/menu-black.svg","alt":"Menu black"}})]):_vm._e()])]),_c('div',{staticClass:"col-md-9 col-12 mobile-margin-top display-flex-header"},[_c('ul',{staticClass:"ul-navbar"},[_c('li',[_c('router-link',{staticClass:"CamptonBook",class:_vm.navBarColor,attrs:{"to":{ name: 'Home' },"exact":"","title":"Accueil"}},[_vm._v("Accueil")])],1),_c('li',[_c('router-link',{staticClass:"CamptonBook",class:_vm.navBarColor,attrs:{"to":{ name: 'Services' },"title":"Services"}},[_vm._v("Services")])],1),_c('li',[_c('router-link',{staticClass:"CamptonBook",class:_vm.navBarColor,attrs:{"to":{ name: 'Company' },"exact":"","title":"Entreprise"}},[_vm._v("Entreprise")])],1),_c('li',[_c('router-link',{staticClass:"CamptonBook",class:_vm.navBarColor,attrs:{"to":{ name: 'Carriere' },"exact":"","title":"Carrière"}},[_vm._v("Carrière")])],1)]),_c('span',{staticClass:"sidebar-nav cursor-pointer show-in-desktop",attrs:{"onclick":"openNav()"}},[(
                _vm.$route.name != 'Company' &&
                _vm.$route.name != 'Conditions-generales'
              )?_c('span',[_c('img',{attrs:{"src":"assets/images/menu.svg","alt":"menu simple"}})]):_vm._e(),(
                _vm.$route.name == 'Company' ||
                _vm.$route.name == 'Conditions-generales'
              )?_c('span',[_c('img',{attrs:{"src":"assets/images/menu-black.svg","alt":"Menu black"}})]):_vm._e()])])])]),_c('div',{staticClass:"col-md-1"})])])}
var staticRenderFns = []

export { render, staticRenderFns }