<template>
  <div>
    <Sidebar></Sidebar>
    <Header></Header>
    <div class="container-fluid background-image-all-pages padding-top-101">
      <div class="row align-items-center service-background">
        <div class="col-md-1"></div>
        <div class="col-md-4">
          <div class="row align-items-center">
            <div class="col-md-12">
              <p
                class="
                  font-size-42
                  CamptonBold
                  text-transform
                  line-height-50
                  color-white
                "
              >
                Nos services
              </p>
              <img
                src="assets/images/services-page.svg"
                class="width-100 show-in-mobile"
                alt="Nos services : SOFTT365"
              />
              <p class="show-in-mobile"></p>
              <p class="text-align-left font-size-20 color-white">
                <strong
                  >Vous avez un besoin ? Nous avons forcément
                  <span class="text-transform">La solution</span> adaptée
                  !</strong
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5 show-in-desktop">
          <img
            src="assets/images/services-page.svg"
            class="width-100"
            alt="Nos services : SOFTT365"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <div class="container-fluid padding-bottom-100" id="list-of-services">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
          <div class="background-283F8F padding-top-bottom-25">
            <ul class="nav nav-tabs" id="tab-services" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link text-transform"
                  id="tab-service-1"
                  data-toggle="tab"
                  href="#service-1"
                  role="tab"
                  aria-controls="service-1"
                  >Développement et maintenance des applications web et
                  mobiles</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-transform"
                  id="tab-service-2"
                  data-toggle="tab"
                  href="#service-2"
                  role="tab"
                  aria-controls="service-2"
                  >Externalisation des compétences</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-transform"
                  id="tab-service-3"
                  data-toggle="tab"
                  href="#service-3"
                  role="tab"
                  aria-controls="service-3"
                  >Implémentation des sites web</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-transform"
                  id="tab-service-4"
                  data-toggle="tab"
                  href="#service-4"
                  role="tab"
                  aria-controls="service-4"
                  >Services connexes</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-7">
          <div class="tab-content tab-content-services">
            <div class="tab-pane" id="service-1" role="tabpanel">
              <div class="row">
                <div class="col-md-9">
                  <p
                    class="
                      font-size-20
                      CamptonBold
                      text-transform
                      line-height-40
                    "
                  >
                    Développement
                    <span class="span-color">des applications web</span> et
                    <span class="span-color">mobiles</span>
                  </p>
                  <p>
                    <strong
                      >Vous souhaitez développer une solution informatique
                      personnalisée « from scratch » dans le cadre de votre
                      transformation digitale ?</strong
                    >
                  </p>
                  <p>
                    <strong
                      >Profitez de la polyvalence et de l’expertise de nos
                      équipes pour vous conseiller et pour créer la solution qui
                      vous facilitera la gestion de votre activité au
                      quotidien.</strong
                    >
                  </p>
                  <p>
                    Nous prenons en charge votre projet dans son intégralité et
                    de façon cohérente. Applications mobiles, sites web,
                    logiciels, nous offrons un développement full stack
                    englobant:
                  </p>
                  <ul>
                    <li>
                      La gestion du projet de création du site web, en lien avec
                      le client et le reste de l’équipe
                    </li>
                    <li>La création du Front-end</li>
                    <li>La programmation du Back-end</li>
                  </ul>
                  <p>Notre objectif à terme ?</p>
                  <p>
                    Elargir notre expertise et vous accompagner dans vos projets
                    en Big data et IA.
                  </p>
                  <p>Nous sommes prêts à relever le défi !</p>
                  <p>
                    Grâce à notre expertise et fidèles à nos valeurs, nous
                    offrons à tous nos clients une garantie après le déploiement
                    de nos solutions. <br />Vous pouvez ensuite profiter d'un
                    contrat de maintenance adapté à vos besoins et à votre
                    budget.
                  </p>
                  <p>
                    <strong
                      >Si vous êtes une start up, profitez de notre formule
                      spéciale ! Nous comprenons les difficultés financières qui
                      accompagne tous les projets ambitieux dans leurs premiers
                      pas. C'est pourquoi nous vous proposons des solutions
                      encourageantes pour vous soutenir dans votre
                      ascension.</strong
                    >
                  </p>
                  <p
                    class="
                      font-size-20
                      CamptonBold
                      text-transform
                      line-height-40
                    "
                  >
                    Maintenance
                    <span class="span-color">des applications web</span> et
                    <span class="span-color">mobiles</span>
                  </p>
                  <p>
                    <strong
                      >Vous disposez déjà d’une solution informatique mais vous
                      souhaitez la faire évoluer pour en améliorer le design
                      et/ou les fonctionnalités ?</strong
                    >
                  </p>
                  <p>
                    <strong
                      >Profitez de notre savoir-faire et de notre adaptabilité
                      dans le cadre d’un contrat de maintenance.</strong
                    >
                  </p>
                  <p>
                    Forts de nos compétences techniques et de notre polyvalence
                    métier, nous saurons rapidement nous familiariser avec votre
                    application et prendre en charge les modifications qui sont
                    nécessaires à son bon fonctionnement et/ou qui sont
                    compatibles avec les évolutions de votre environnement.
                  </p>
                </div>
                <div class="col-md-3">
                  <img
                    src="assets/images/service-icon-1.svg"
                    class="width-100"
                    alt="Service SOFTT365 : Maintenance"
                  />
                </div>
              </div>
            </div>
            <div class="tab-pane" id="service-2" role="tabpanel">
              <div class="row">
                <div class="col-md-9">
                  <p
                    class="
                      font-size-20
                      CamptonBold
                      text-transform
                      line-height-40
                    "
                  >
                    Externalisation des
                    <span class="span-color">compétences</span>
                  </p>
                  <p>
                    <strong
                      >L'externalisation de compétences, LA SOLUTION à la rareté
                      des ressources.</strong
                    >
                  </p>
                  <p>
                    Pour faire face à la rareté des ressources et concentrer les
                    efforts de vos équipes internes sur votre cœur de métier,
                    vous pouvez vous appuyer sur des ressources externalisées
                    recrutées, formées, intégrées, gérées et fidélisées par
                    <strong>SOFTT365</strong>.
                  </p>
                </div>
                <div class="col-md-3">
                  <img
                    src="assets/images/service-icon-2.svg"
                    class="width-100"
                    alt="Service SOFTT365 : Externalisation des compétences"
                  />
                </div>
              </div>
            </div>
            <div class="tab-pane" id="service-3" role="tabpanel">
              <div class="row">
                <div class="col-md-9">
                  <p
                    class="
                      font-size-20
                      CamptonBold
                      text-transform
                      line-height-40
                    "
                  >
                    Implémentation des <span class="span-color">sites web</span>
                  </p>
                  <p>
                    Choisir le bon prestataire est un défi de taille pour qui
                    souhaite réussir sa transition digitale et pérenniser son
                    activité sur internet.
                  </p>
                  <p>
                    <strong>SOFTT365</strong> réalise votre projet web
                    sur-mesure.
                  </p>
                  <p>
                    <strong>SOFTT365</strong> combine un savoir-faire complet
                    avec une vison tournée vers l’innovation et la performance.
                  </p>
                  <p>
                    Notre équipe de développeurs web met au point des
                    architectures techniques robustes et pérennes, dans des
                    technologies stables et éprouvées, utilisées largement dans
                    le monde.
                  </p>
                </div>
                <div class="col-md-3">
                  <img
                    src="assets/images/service-icon-3.svg"
                    class="width-100"
                    alt="Service SOFTT365 : Implémentation des sites web"
                  />
                </div>
              </div>
            </div>
            <div class="tab-pane" id="service-4" role="tabpanel">
              <div class="row">
                <div class="col-md-9">
                  <p
                    class="
                      font-size-20
                      CamptonBold
                      text-transform
                      line-height-40
                    "
                  >
                    <span class="span-color">Services</span> connexes
                  </p>
                  <p>
                    <strong
                      >Besoin d'aide pour optimiser la rentabilité de la
                      solution digitale dont vous disposez ? Nous vous offrons
                      une panoplie de service qui vous permettra de rester
                      concentrés sur votre cœur de métier.</strong
                    >
                  </p>
                  <p>
                    Pour un service à 365°, nous nous engageons à fournir à nos
                    clients la panoplie de services complémentaires nécessaires
                    à la valorisation de leurs solutions digitales.
                    <br />
                    Notre expertise en services d’assistance nous permet
                    d’accomplir avec succès toutes sortes de missions support à
                    distance et de proposer à nos clients un soutien
                    administratif complet allant des tâches les plus classiques
                    au plus spécialisées.
                    <br />
                    Ce que vous y gagnez :
                  </p>
                  <ul>
                    <li>
                      Un vivier de compétences à votre disposition sans effort
                      de recherche et sans investissement.
                    </li>
                    <li>
                      Des compétences à votre service sans charges salariales.
                    </li>
                    <li>Une Facture déductible à 100% fiscalement.</li>
                  </ul>
                </div>
                <div class="col-md-3">
                  <img
                    src="assets/images/service-icon-4.svg"
                    class="width-100"
                    alt="Service SOFTT365 : Services connexes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import JQuery from "jquery";
export default {
  name: "Services",
  data() {
    return {
      url_data: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    document.title = "SOFTT365 : Services";
    if (this.$route.params.tab) {
      this.url_data = this.$route.params.tab;
      JQuery(".nav-item").removeClass("active");
      JQuery(".tab-pane").removeClass("active");
      JQuery("#tab-" + this.url_data).addClass("active");
      JQuery("#" + this.url_data).addClass("active");
    } else {
      JQuery("#tab-service-1").addClass("active");
      JQuery("#service-1").addClass("active");
    }
  },
  components: {},
};
</script>
