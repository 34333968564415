<template>
  <div>
    <Sidebar></Sidebar>
    <Header></Header>
    <!-- FIRST BLOCK -->
    <div class="background-image">
      <div class="container-fluid" style="margin: auto; margin-left: 0">
        <div class="row align-items-center">
          <div class="col-md-1"></div>
          <div class="col-md-5 in-mobile-margin-top-120">
            <p>&nbsp;</p>
            <div
              class="
                margin-bottom-0
                font-size-42
                CamptonBold
                text-transform
                color-white
                mobile-line-height
                line-height-50
              "
            >
              La technologie sur mesure
            </div>
            <p class="show-in-mobile margin-top-20">
              <img
                src="assets/images/icone-home-slider.svg"
                class="width-100"
                alt="SOFTT365 est une société de développement
                informatique et de création des logiciels métiers sur mesure"
              />
            </p>
            <p class="margin-bottom-0">&nbsp;</p>
            <div class="color-white">
              <p>
                <strong>SOFTT365</strong> est une société de développement
                informatique et de création des logiciels métiers sur mesure,
                opérant principalement sur les secteurs de l'Industrie, de la
                Finance et de l’immobilier.
                <br />
                Grâce à notre équipe hautement qualifiée, nous vous offrons des
                solutions simples, personnalisées et surtout rentables qui vous
                aident à optimiser la gestion de votre entreprise et à garantir
                sa compétitivité face à la concurrence.
              </p>
            </div>
            <div>
              <p>
                <router-link :to="{ name: 'Services' }" title="Nos services"
                  ><span class="btn btn-softt">Nos services</span></router-link
                >
              </p>
              <p class="show-in-mobile">&nbsp;</p>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <img
              src="assets/images/icone-home-slider.svg"
              class="width-100 show-in-desktop"
              alt="SOFTT365 est une société de développement
                informatique et de création des logiciels métiers sur mesure"
            />
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="show-in-desktop">
          <div class="row row-absolute">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-4 text-align-left">
                  <p>
                    <!-- <a href="javascript:void(0)" class="color-white rgpd" title="RGPD">RGPD</a> -->
                  </p>
                </div>
                <div class="col-md-4 text-align-center">
                  <img
                    src="assets/images/scroll-down.png"
                    class="scroll-down"
                    v-on:click="scrollToElement({ behavior: 'smooth' })"
                    alt="Image SCROLL DOWN"
                  />
                </div>
                <div class="col-md-4 text-align-right">
                  <p>
                    <!-- <a href="#" title="Facebook">
                                        <img src="assets/images/facebook-footer.svg" alt="" />
                                    </a>
                                    &nbsp;&nbsp; -->
                    <a
                      href="https://www.linkedin.com/company/softt-365/mycompany/"
                      target="_blank"
                      title="Linkedin"
                    >
                      <img
                        src="assets/images/linkedin-footer.svg"
                        alt="Linkedin SOFTT365"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
    <!--- OUR SERVICES -->
    <div
      id="first-block-home-page"
      class="container-fluid padding-top-50 display-none-if-click-in-service"
    >
      <div class="row align-items-center">
        <div class="col-md-12 text-align-center">
          <p class="font-size-42 CamptonBold text-transform line-height-50">
            Nos services
          </p>
          <p></p>
        </div>
      </div>
      <div class="row slider-block margin-top-50">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <!-- SLIDER -->
          <div class="row">
            <div class="col-md-3">
              <div class="container-services mobile-margin-bottom-25">
                <router-link
                  :to="{
                    name: 'Services',
                    hash: '#list-of-services',
                    params: { tab: 'service-1' },
                  }"
                >
                  <img
                    src="assets/images/service-icon-1.svg"
                    class="width-100 height-170"
                    alt="Service : Développement et maintenance des app web et mobiles"
                  />
                  <p class="CamptonSemiBold text-transform text-align-center">
                    Développement et maintenance des app web et mobiles
                  </p>
                </router-link>
              </div>
            </div>
            <div class="col-md-3">
              <div class="container-services mobile-margin-bottom-25">
                <router-link
                  :to="{
                    name: 'Services',
                    hash: '#list-of-services',
                    params: { tab: 'service-2' },
                  }"
                >
                  <img
                    src="assets/images/service-icon-2.svg"
                    class="width-100 height-170"
                    alt="Service : Externalisation des compétences"
                  />
                  <p class="CamptonSemiBold text-transform text-align-center">
                    Externalisation des compétences
                  </p>
                </router-link>
              </div>
            </div>
            <div class="col-md-3">
              <div class="container-services mobile-margin-bottom-25">
                <router-link
                  :to="{
                    name: 'Services',
                    hash: '#list-of-services',
                    params: { tab: 'service-3' },
                  }"
                >
                  <img
                    src="assets/images/service-icon-3.svg"
                    class="width-100 height-170"
                    alt="Service : Implémentation des sites web"
                  />
                  <p class="CamptonSemiBold text-transform text-align-center">
                    Implémentation des sites web
                  </p>
                </router-link>
              </div>
            </div>
            <div class="col-md-3">
              <div class="container-services mobile-margin-bottom-25">
                <router-link
                  :to="{
                    name: 'Services',
                    hash: '#list-of-services',
                    params: { tab: 'service-4' },
                  }"
                >
                  <img
                    src="assets/images/service-icon-4.svg"
                    class="width-100 height-170"
                    alt="Service : Services connexes"
                  />
                  <p class="CamptonSemiBold text-transform text-align-center">
                    Services connexes
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- WHY US -->
    <div
      class="container-fluid margin-top-100 padding-bottom-100 background-grise"
    >
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row padding-top-100 align-items-center">
            <div class="col-md-4">
              <p class="CamptonBold font-size-42 line-height-50 text-transform">
                Pourquoi choisir <strong>SOFTT365</strong> ?
              </p>
              <p class="text-align-left font-size-20">
                <strong
                  >5 Bénéfices pour une relation de travail 5 étoiles !</strong
                >
              </p>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-12">
                  <ul class="nav nav-tabs">
                    <li>
                      <a href="#tab-1" class="active" data-toggle="tab"
                        ><div class="tab-item">
                          <img
                            src="assets/images/nous-1.svg"
                            class="icon-us show"
                            alt="Pourquoi choisir SOFTT365"
                          /><img
                            src="assets/images/nous-1-black.svg"
                            class="icon-us hide"
                            alt="Pourquoi choisir SOFTT365"
                          /></div
                      ></a>
                    </li>
                    <li>
                      <a href="#tab-2" data-toggle="tab"
                        ><div class="tab-item">
                          <img
                            src="assets/images/nous-2.svg"
                            class="icon-us show"
                            alt="Pourquoi choisir SOFTT365"
                          /><img
                            src="assets/images/nous-2-black.svg"
                            class="icon-us hide"
                            alt="Pourquoi choisir SOFTT365"
                          /></div
                      ></a>
                    </li>
                    <li>
                      <a href="#tab-3" data-toggle="tab"
                        ><div class="tab-item">
                          <img
                            src="assets/images/nous-3.svg"
                            class="icon-us show"
                            alt="Pourquoi choisir SOFTT365"
                          /><img
                            src="assets/images/nous-3-black.svg"
                            class="icon-us hide"
                            alt="Pourquoi choisir SOFTT365"
                          /></div
                      ></a>
                    </li>
                    <li>
                      <a href="#tab-4" data-toggle="tab"
                        ><div class="tab-item">
                          <img
                            src="assets/images/nous-5.svg"
                            class="icon-us show"
                            alt="Pourquoi choisir SOFTT365"
                          /><img
                            src="assets/images/nous-4-black.svg"
                            class="icon-us hide"
                            alt="Pourquoi choisir SOFTT365"
                          /></div
                      ></a>
                    </li>
                    <li>
                      <a href="#tab-5" data-toggle="tab"
                        ><div class="tab-item">
                          <img
                            src="assets/images/nous-4.svg"
                            class="icon-us show"
                            alt="Pourquoi choisir SOFTT365"
                          /><img
                            src="assets/images/nous-5-black.svg"
                            class="icon-us hide"
                            alt="Pourquoi choisir SOFTT365"
                          /></div
                      ></a>
                    </li>
                  </ul>
                  <div class="tab-content" id="tabs">
                    <div class="tab-pane active" id="tab-1">
                      <p class="tab-content">
                        Nous avons mis en place un système de veille
                        technologique permanente grâce à notre équipe qui
                        maîtrise un large éventail de technologies et de
                        métiers. C’est pourquoi nous sommes capables de
                        comprendre rapidement vos besoins et de vous proposer
                        des solutions adaptées et efficaces. Nos clients en sont
                        les meilleurs ambassadeurs.
                      </p>
                    </div>
                    <div class="tab-pane" id="tab-2">
                      <p class="tab-content">
                        Notre équipe, triée sur le volet, est particulièrement
                        compétente et flexible. C’est pourquoi nous avons une
                        aisance particulière pour solutionner les bugs de vos
                        applications déjà existantes et/ou à les refondre pour
                        tenir compte des évolutions de votre environnement ou de
                        votre métier.
                      </p>
                    </div>
                    <div class="tab-pane" id="tab-3">
                      <p class="tab-content">
                        Nous sommes passionnés par notre métier. C’est pourquoi
                        nous vous garantissons un service sur mesure basée sur
                        l’écoute, la disponibilité et l’implication de nos
                        équipes.
                      </p>
                    </div>
                    <div class="tab-pane" id="tab-4">
                      <p class="tab-content">
                        Nous sommes orientés business. C’est pourquoi nous
                        savons que la maîtrise du temps est une clé de succès
                        pour nos clients et que nous sommes particulièrement
                        rigoureux en termes de délais.
                      </p>
                    </div>
                    <div class="tab-pane" id="tab-5">
                      <p class="tab-content">
                        Nous considérons nos clients comme des partenaires,
                        votre succès est aussi le nôtre. C’est pourquoi nous
                        offrons des produits et des services de qualité à des
                        coûts particulièrement attractifs, exonérés de la TVA.
                        Si vous êtes une start up, vous pouvez bénéficier de
                        notre offre spéciale.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- CUSTOMERS -->
    <div class="container-fluid padding-top-100 background-2F3542">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-6">
              <div class="row mobile-margin-bottom-25">
                <div
                  v-for="(item, key) in itemsCustomer"
                  :key="item.img.id"
                  class="col-md-4 col-6 margin-bottom-25"
                >
                  <div class="background-white">
                    <img
                      :src="hovered === key ? item.img.green : item.img.grey"
                      @mouseover="hovered = key"
                      @mouseleave="hovered = null"
                      class="width-100 display-inline object-fit-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 show-in-desktop"></div>
            <div class="col-md-5">
              <p
                class="
                  CamptonBold
                  color-white
                  font-size-42
                  line-height-50
                  text-transform
                "
              >
                NOS CLIENTS
              </p>
              <p class="color-white mobile-margin-bottom-25">
                <strong class="text-align-left font-size-20"
                  >La satisfaction de nos clients est notre meilleure récompense
                  !</strong
                >
                <br />Nous sommes fiers d’avoir obtenu la confiance de clients à
                l'international, évoluant dans des secteurs divers tels que
                l’assurance, l’immobilier, la gestion de contrats, la
                communication et autres …
              </p>
              <!-- <div class="mobile-margin-bottom-25">
                            <router-link :to="{ name: 'Company'}" class="btn btn-softt" title="En savoir plus">En savoir plus</router-link>
                        </div> -->
              <p class="show-in-mobile">&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-5 margin-top-50">
          <iframe
            sandbox
            style="width: 100%"
            class="video-iframe"
            src="assets/video/video-contracktime.mp4"
          ></iframe>
        </div>
        <div class="col-md-5 margin-top-50">
          <Flickity class="flickity" ref="flickity" :options="flickityOptions">
            <div class="carousel-cell item-slider-2">
              <p>
                <i class="fas fa-quote-left"></i>
                <br />
                Comme pour n’importe quel projet IT, des aléas techniques et
                humains sont apparus en cours de projet et SOFTT365 a toujours
                pris le soin d’y répondre de manière calme et pondérée tout en
                maintenant une vision à long terme : aller au bout du projet ...
                <span
                  class="cursor-pointer color-77AEFF"
                  v-on:click="clickVideo"
                  ><strong> lire la suite</strong></span
                >.
              </p>
              <p>
                <span class="display-inline">
                  <span class="CamptonMedium">Nicolas FOREST</span>
                  <br />
                  <strong>CEO Forest CM</strong>
                </span>
              </p>
            </div>
          </Flickity>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- TECHNOLOGIES -->
    <div class="container-fluid padding-bottom-100 padding-top-100">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row" id="technologies">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'react'
                          ? 'assets/images/react-color.svg'
                          : 'assets/images/react.svg'
                      "
                      @mouseover="hovered = 'react'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en React"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'angular'
                          ? 'assets/images/angular-color.svg'
                          : 'assets/images/angular.svg'
                      "
                      @mouseover="hovered = 'angular'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en Angular"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'jquery'
                          ? 'assets/images/JQuery-color.svg'
                          : 'assets/images/JQuery.svg'
                      "
                      @mouseover="hovered = 'jquery'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en JQuery"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'adobe'
                          ? 'assets/images/adobe-color.svg'
                          : 'assets/images/adobe.svg'
                      "
                      @mouseover="hovered = 'adobe'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en Adobe"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'nodejs'
                          ? 'assets/images/nodejs-color.svg'
                          : 'assets/images/nodejs.svg'
                      "
                      @mouseover="hovered = 'nodejs'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en Node"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'dotnetcore'
                          ? 'assets/uploads/net-core.svg'
                          : 'assets/uploads/net-core-black.svg'
                      "
                      @mouseover="hovered = 'dotnetcore'"
                      @mouseleave="hovered = false"
                      style="width: 60px"
                      class="height-70"
                      alt="Nos compétences en Dot Net Core"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'symfony'
                          ? 'assets/images/symfony.svg'
                          : 'assets/images/symfony.svg'
                      "
                      @mouseover="hovered = 'symfony'"
                      @mouseleave="hovered = false"
                      style="width: 60px"
                      class="height-70"
                      alt="Nos compétences en Symfony"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'laravel'
                          ? 'assets/images/laravel-color.svg'
                          : 'assets/images/laravel.svg'
                      "
                      @mouseover="hovered = 'laravel'"
                      @mouseleave="hovered = false"
                      style="width: 50px"
                      class="height-70"
                      alt="Nos compétences en Laravel"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'aws'
                          ? 'assets/images/aws-color.svg'
                          : 'assets/images/aws.svg'
                      "
                      @mouseover="hovered = 'aws'"
                      @mouseleave="hovered = false"
                      style="width: 60px"
                      class="height-70"
                      alt="Nos compétences en AWS"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'azure'
                          ? 'assets/images/azure-color.svg'
                          : 'assets/images/azure.svg'
                      "
                      @mouseover="hovered = 'azure'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en Azure"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'ovh'
                          ? 'assets/images/ovh-color.svg'
                          : 'assets/images/ovh.svg'
                      "
                      @mouseover="hovered = 'ovh'"
                      @mouseleave="hovered = false"
                      class="height-70"
                      alt="Nos compétences en OVH"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-4 margin-bottom-25 text-align-center">
                  <div class="border-techno">
                    <img
                      :src="
                        hovered === 'google-cloud'
                          ? 'assets/images/google-cloud-color.svg'
                          : 'assets/images/google-cloud.svg'
                      "
                      @mouseover="hovered = 'google-cloud'"
                      @mouseleave="hovered = false"
                      style="width: 50px"
                      class="height-70"
                      alt="Nos compétences en Google Cloud"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 show-in-desktop"></div>
            <div class="col-md-5">
              <p class="CamptonBold font-size-42 line-height-50 text-transform">
                NOS TECHNOLOGIES
              </p>
              <p class="mobile-margin-bottom-25">
                Pour des résultats à la hauteur de vos attentes, notre
                entreprise dispose d’un éventail de technologies web/mobile
                entièrement dédiées à votre projet digital
              </p>
              <p class="show-in-mobile">&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- OUR VALUES -->
    <div
      class="
        container-fluid
        padding-top-100 padding-bottom-100
        background-grise
      "
    >
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="padding-left-25">
                <p
                  class="CamptonBold font-size-42 line-height-50 text-transform"
                >
                  Notre équipe
                </p>
                <p class="text-align-left font-size-20">
                  <strong
                    >Vous avez un projet ? Notre équipe s’engage à vos côtés
                    !</strong
                  >
                </p>
                <p class="show-in-mobile">&nbsp;</p>
                <div
                  class="
                    show-in-mobile
                    text-align-center
                    mobile-margin-bottom-25
                  "
                >
                  <img
                    src="assets/images/team.svg"
                    class="max-height-400 width-100"
                    alt="SOFTT365 : Notre Equipe"
                  />
                </div>
                <p class="show-in-mobile">&nbsp;</p>
                <p>
                  Notre équipe est composée d’ingénieurs et de techniciens
                  informatiques qualifiés, de tous les horizons et de tous les
                  niveaux d’expérience.<br />Ils maîtrisent un large panel de
                  technologies et ils sont dotés des soft skills nécessaires ce
                  qui nous permet de comprendre rapidement vos besoins, de vous
                  fournir des produits efficaces et surtout de vous fournir un
                  service à 365° de haute qualité.
                </p>
                <p class="show-in-mobile">&nbsp;</p>
                <div class="mobile-margin-bottom-25">
                  <router-link
                    :to="{ name: 'Company' }"
                    class="btn btn-softt"
                    title="En savoir plus"
                    >En savoir plus</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6 text-align-center show-in-desktop">
              <img
                src="assets/images/team.svg"
                class="max-height-550 width-100"
                alt="SOFTT365 : Notre Equipe"
              />
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- FORM CONTACT -->
    <div class="container-fluid margin-top-50" id="contact-block">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12 text-align-center">
              <p class="our-customers CamptonBold text-transform">
                Vous avez un <span class="color-77AEFF">projet</span> ?
              </p>
              <p>
                Contactez-nous ! Nous vous rappellerons sous les 24H pour en
                discuter !
                <br />
                Profitez de la flexibilité d'une petite structure pleine de
                compétences et d'engagement!
              </p>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <p>&nbsp;</p>
              <form
                method="post"
                v-on:submit.prevent="contact"
                id="contact-form"
                action=""
              >
                <div class="row items-contact-form">
                  <div class="col-md-4 col-6">
                    <input
                      type="text"
                      name="fullname"
                      v-model="form.fullname"
                      class="form-control input"
                      placeholder="Nom et prénom"
                    />
                  </div>
                  <div class="col-md-4 col-6">
                    <input
                      type="text"
                      name="agency"
                      v-model="form.agency"
                      class="form-control input"
                      placeholder="Entreprise "
                    />
                  </div>
                  <div class="col-md-4 col-12">
                    <input
                      type="email"
                      name="email"
                      v-model="form.email"
                      class="form-control input"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="row items-contact-form">
                  <div class="col-md-12">
                    <textarea
                      rows="8"
                      name="message"
                      v-model="form.message"
                      class="input textarea"
                      placeholder="Votre message"
                    ></textarea>
                  </div>
                </div>
                <div class="row text-align-center">
                  <div class="col-md-12">
                    <div id="msg-contact"></div>
                    <button
                      class="btn btn-softt width-120"
                      id="send-contact-form"
                      title="Envoyer"
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "Home",
  components: {
    Flickity,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.title = "SOFTT365 : Page d'accueil";
  },
  data() {
    return {
      form: {
        fullname: "",
        email: "",
        agency: "",
        message: "",
      },
      flickityOptions: {
        contain: true,
        wrapAround: false,
        cellAlign: "center",
        groupCells: 1,
        freeScroll: false,
        pageDots: false,
        prevNextButtons: false,
      },
      hovered: null,
      itemsCustomer: {
        item1: {
          img: {
            id: 1,
            grey: require("/public/assets/uploads/my-aviza-black.svg"),
            green: require("/public/assets/uploads/my-aviza.svg"),
          },
        },
        item2: {
          img: {
            id: 2,
            grey: require("/public/assets/uploads/ariane-black.svg"),
            green: require("/public/assets/uploads/ariane-black.svg"),
          },
        },
        item3: {
          img: {
            id: 3,
            grey: require("/public/assets/uploads/contracktime-black.svg"),
            green: require("/public/assets/uploads/contracktime.svg"),
          },
        },
        item4: {
          img: {
            id: 4,
            grey: require("/public/assets/uploads/my-realtor-black.svg"),
            green: require("/public/assets/uploads/my-realtor-black.svg"),
          },
        },
        item5: {
          img: {
            id: 5,
            grey: require("/public/assets/uploads/fil-rouge-black.svg"),
            green: require("/public/assets/uploads/fil-rouge.svg"),
          },
        },
        item6: {
          img: {
            id: 6,
            grey: require("/public/assets/uploads/rps-black.svg"),
            green: require("/public/assets/uploads/rps.svg"),
          },
        },
        item7: {
          img: {
            id: 7,
            grey: require("/public/assets/uploads/adibox-black.svg"),
            green: require("/public/assets/uploads/adibox.svg"),
          },
        },
          item8: {
          img: {
            id: 8,
            grey: require("/public/assets/uploads/medicimmo white  (1).svg"),
            green: require("/public/assets/uploads/logo medicimmo 1.svg"),
          },
        },
      },
    };
  },
  methods: {
    scrollToElement(options) {
      const el = document.getElementById("first-block-home-page");
      if (el) {
        el.scrollIntoView(options);
      }
    },
    clickVideo: function () {
      console.log("test");
      Swal.fire({
        title: "Contracktime",
        html: `
                    <p class="text-align-left">
                        <i class="fas fa-quote-left"></i>
                        <br />
                        Comme pour n’importe quel projet IT, des aléas techniques et humains sont apparus en cours de projet et SOFTT365 a toujours pris le soin d’y répondre de manière calme et pondérée tout en maintenant une vision à long terme : aller au bout du projet
                    </p>
                    <p class="text-align-left">
                        Quand des questions techniques ont eu besoin d’être répondues, SOFTT365 a toujours exprimé en toute franchise ses limites en termes de compétences et ressources disponibles et proposé en conséquence un plan d’action pour trouver la solution.
                    </p>
                    <p class="text-align-left">
                        SOFTT365 prend le temps nécessaire d’analyse et présente la solution la plus raisonnable en fonction des capacités financières et humaines du projet.
                    </p>
                    <p class="text-align-left">
                        Une approche raisonnable, une communication simple et efficace permettent d’avoir une qualité exceptionnelle du relationnel au quotidien.
                    </p>
                    <p class="text-align-left">
                        Cette relation de confiance a grandi jour après jour ; et après près d’un an de collaboration, elle aura finalement naturellement amené à faire évoluer notre relation : on signe initialement un contrat de sous-traitance et à l’issue on rencontre un véritable partenaire.
                    </p>
                    <p class="text-align-left">
                        SOFTT365 a complètement adhéré à nos ambitions pour notre solution et accepté un partenariat sur le long terme en fournissant son expertise et ses ressources. Volonté d’innover dans la façon de travailler et prise de risque pour construire un projet win-win avec nous : une approche entrepreneuriale qui ne peut que séduire et mettre en confiance sur la qualité du travail produit.
                    </p>
                    <p class="text-align-left">
                        Un tout grand merci à SOFTT365 pour son engagement au quotidien, la disponibilité et compétence de ses équipes !
                    </p>`,
        icon: "",
        confirmButtonText: "Fermer",
      });
    },
    contact() {
      axios
        .post("assets/send/contact.php", this.form)
        .then((resp) => {
          Swal.fire({
            title: "Formulaire de contact",
            html: resp["data"],
            icon: "",
          });
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({
            title: "Oops",
            text: e,
            icon: "warning",
          });
        });
    },
  },
};
</script>
