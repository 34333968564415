import Home from "../views/Home.vue";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/services",
    name: "Services",
    component: require("../views/Services.vue").default,
    props: true,
  },
  {
    path: "/company",
    name: "Company",
    component: require("../views/Company.vue").default,
    props: true,
  },
  {
    path: "/carriere",
    name: "Carriere",
    component: require("../views/Carriere.vue").default,
    props: true,
  },
  {
    path: "/terms-and-conditions",
    name: "Conditions-generales",
    component: require("../views/Terms-and-conditions.vue").default,
    props: true,
  },
];
