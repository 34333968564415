<template>
  <div>
    <Sidebar></Sidebar>
    <Header></Header>
    <div class="container-fluid background-image-all-pages padding-top-70">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row margin-top-120 min-height-86-vh align-items-center">
            <div
              class="col-md-12 margin-top-50 default-hide join-us"
              style="display: block"
            >
              <div class="row">
                <div class="col-md-4">
                  <p
                    class="
                      font-size-42
                      CamptonBold
                      text-transform
                      line-height-50
                      color-white
                    "
                  >
                    Rejoigniez <br />notre équipe !
                  </p>
                  <p class="color-white">
                    Nous faisons appel partout en Tunisie à des talents pour
                    réaliser les missions de nos clients. Nous entretenons une
                    relation très privilégiée avec notre équipe basée sur le
                    respect, la confiance, le professionnalisme, l’exigence de
                    qualité, des outils et méthodes de travail partagés et notre
                    passion pour la Technologie et le travail bien fait.
                  </p>
                  <div class="mobile-margin-bottom-25">
                    <button
                      class="btn btn-softt waves-effect waves-light"
                      v-on:click="scrollToElement({ behavior: 'smooth' })"
                      title="Candidature spontanée"
                    >
                      Candidature spontanée
                    </button>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-7 overflow-auto">
                  <div class="row">
                    <div class="col-md-11">

                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="cdi default-emploi">CDI</span>  DEVELOPPEUR WEB FULL STACK (H/F)
                            <span class="CamptonBook text-transform-lowercase"
                              >(REF :  DEVWEB-03/2023)</span
                            >
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Full Time
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/career/offres-Full-Stack-PHP DEVPHP-022023.docx"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>

                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="cdi default-emploi">CDI</span>  DEVELOPPEUR .Net FULL STACK (H/F)
                            <span class="CamptonBook text-transform-lowercase"
                              >(REF :  DEVDOTNET-02/2023)</span
                            >
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Full Time
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/career/offres-Full-Stack-.Net DEVDOTNET-022023.docx"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>

                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="cdi default-emploi">CDI</span>  DEVELOPPEUR WEB FULL STACK (H/F)
                            <span class="CamptonBook text-transform-lowercase"
                              >(REF :  DEVWFS-01/2023)</span
                            >
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Full Time
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/career/offres-Full-Stack-JS.pdf"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>

                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="stage default-emploi">PEF</span> Notre
                            PFE BOOK
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Stage
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/Softt365-PFE-2022.pdf"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>

                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="cdi default-emploi">CDI</span> .Net
                            core (H/F)
                            <span class="CamptonBook text-transform-lowercase"
                              >(REF : DEVNETCORE-01/2022)</span
                            >
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Full Time
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/career/offres-Full-Stack-.Net.docx"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>
                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="cdi default-emploi">CDI</span> Graphic
                            designer (H/F)
                            <span class="CamptonBook text-transform-lowercase"
                              >(REF: GDESI-01/2022)</span
                            >
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Full Time
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/career/offres-Graphic-Designer.docx"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>
                      <div class="posts-block row align-items-center">
                        <div class="col-md-8">
                          <p class="CamptonMedium margin-bottom-0 color-white">
                            <span class="cdi default-emploi">CDI</span>
                            Développeur full-stack - React (H/F)
                            <span class="CamptonBook text-transform-lowercase"
                              >(REF : DEVWFS-01/2022)</span
                            >
                          </p>
                          <div class="row">
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/time.svg"
                                  class="margin-right-10"
                                  alt="Icône horaire"
                                />
                                Full Time
                              </p>
                            </div>
                            <div class="col-md-6 color-white">
                              <p>
                                <img
                                  src="assets/images/locality.svg"
                                  class="margin-right-10"
                                  alt="Icône localité"
                                />
                                Tunis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-align-right">
                          <p>
                            <a
                              href="assets/uploads/career/offres-Full-Stack-JS.docx"
                              target="_blank"
                              download
                              class="text-transform"
                              title="En savoir plus"
                              ><span class="read-more">En savoir plus</span></a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                </div>
              </div>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <!-- FORM CONTACT -->
    <div class="container-fluid padding-top-100" id="careers-block">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12 text-align-center">
              <p class="our-customers CamptonBold text-transform">
                candidature <span class="color-77AEFF">spontanée</span> ?
              </p>
              <p>
                En soumettant ce formulaire, j’accepte que les informations
                saisies soient traitées pour permettre de me recontacter et
                répondre à ma demande d’information.
              </p>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <p>&nbsp;</p>
              <form
                method="post"
                @submit.prevent="spontaneous"
                id="spontaneous-application-form"
                action=""
              >
                <div class="row">
                  <div class="col-md-6 col-12">
                    <input
                      type="text"
                      name="fullname"
                      class="form-control input"
                      placeholder="Nom et prénom"
                      v-model="form.fullname"
                    />
                  </div>
                  <div class="col-md-6 col-12">
                    <input
                      type="email"
                      name="email"
                      class="form-control input"
                      placeholder="Email"
                      v-model="form.email"
                    />
                  </div>
                  <div class="col-md-12 col-12">
                    <select
                      type="type"
                      name="type"
                      class="form-control input"
                      placeholder="Sélectionner le type"
                      v-model="form.type"
                    >
                      <option selected>-- Sélectionner le type --</option>
                      <option value="Candidature spontanée">
                        Candidature spontanée
                      </option>
                      <option value="Demande de stage">Demande de stage</option>
                    </select>
                  </div>
                  <div class="col-md-12 col-12">
                    Curriculum vitae (CV)
                    <br />
                    <input
                      type="file"
                      @change="upload($event)"
                      id="file-input"
                    />
                    <input
                      type="hidden"
                      name="upload"
                      v-model="form.upload"
                      id="upload"
                    />
                  </div>
                  <div class="col-md-12">
                    <p>&nbsp;</p>
                  </div>
                  <div class="row text-align-center">
                    <div class="col-md-12">
                      <div id="msg-spontaneous-application-form"></div>
                      <button
                        class="btn btn-softt width-120"
                        id="send-spontaneous-application-form"
                        title="Envoyer"
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "Carriere",
  mounted() {
    window.scrollTo(0, 0);
    document.title = "SOFTT365 : Carrière";
  },
  data() {
    return {
      form: {
        fullname: "",
        email: "",
        type: "Candidature spontanée",
        file: "",
        upload: "",
      },
    };
  },
  methods: {
    scrollToElement(options) {
      const el = document.getElementById("careers-block");
      if (el) {
        el.scrollIntoView(options);
      }
    },
    upload(event) {
      let data = new FormData();
      let file = event.target.files[0];
      data.append("name", "my-file");
      data.append("file", file);
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post("assets/send/upload-file.php", data, config).then((resp) => {
        this.form.upload = resp["data"];
        document.getElementById("upload").value = resp["data"];
      });
    },
    spontaneous() {
      axios
        .post("assets/send/spontaneous-application.php", this.form)
        .then((resp) => {
          Swal.fire({
            title: "Formulaire de contact",
            html: resp["data"],
            icon: "",
          });
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({
            title: "Oops",
            text: e,
            icon: "warning",
          });
        });
    },
  },
};
</script>
