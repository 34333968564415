<template>
  <!-- NAVBAR -->
  <div class="container-fluid top-header">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <!-- HEADER -->
        <div class="row header">
          <div class="col-md-3 col-6">
            <router-link
              :to="{ name: 'Home' }"
              title="Accueil"
              class="CamptonBook"
            >
              <span
                v-if="
                  $route.name == 'Company' ||
                  $route.name == 'Conditions-generales'
                "
              >
                <img
                  src="assets/images/logo-other-page.svg"
                  alt="Logo SOFTT 365"
                />
              </span>
              <span
                v-if="
                  $route.name != 'Company' &&
                  $route.name != 'Conditions-generales'
                "
              >
                <img src="assets/images/logo.svg" alt="Logo SOFTT 365" />
              </span>
            </router-link>
          </div>
          <div class="col-6 show-in-mobile text-align-right">
            <span onclick="openNav()" class="sidebar-nav cursor-pointer">
              <span
                v-if="
                  $route.name == 'Company' ||
                  $route.name == 'Conditions-generales'
                "
              >
                <img src="assets/images/menu.svg" alt="Société" />
              </span>
              <span
                v-if="
                  $route.name != 'Company' &&
                  $route.name != 'Conditions-generales'
                "
              >
                <img src="assets/images/menu-black.svg" alt="Menu black" />
              </span>
            </span>
          </div>
          <div class="col-md-9 col-12 mobile-margin-top display-flex-header">
            <ul class="ul-navbar">
              <li>
                <router-link
                  :to="{ name: 'Home' }"
                  exact
                  title="Accueil"
                  class="CamptonBook"
                  :class="navBarColor"
                  >Accueil</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'Services' }"
                  title="Services"
                  class="CamptonBook"
                  :class="navBarColor"
                  >Services</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'Company' }"
                  exact
                  title="Entreprise"
                  class="CamptonBook"
                  :class="navBarColor"
                  >Entreprise</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'Carriere' }"
                  exact
                  title="Carrière"
                  class="CamptonBook"
                  :class="navBarColor"
                  >Carrière</router-link
                >
              </li>
            </ul>
            <span
              onclick="openNav()"
              class="sidebar-nav cursor-pointer show-in-desktop"
            >
              <span
                v-if="
                  $route.name != 'Company' &&
                  $route.name != 'Conditions-generales'
                "
              >
                <img src="assets/images/menu.svg" alt="menu simple" />
              </span>
              <span
                v-if="
                  $route.name == 'Company' ||
                  $route.name == 'Conditions-generales'
                "
              >
                <img src="assets/images/menu-black.svg" alt="Menu black" />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    navBarColor() {
      if (
        this.$route.path !== "/company" &&
        this.$route.path !== "/terms-and-conditions"
      ) {
        return "color-white";
      }
      return "color-2F3542";
    },
  },
};
</script>