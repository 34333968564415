<template>
<div>
    <Sidebar></Sidebar>
    <Header></Header>
    <div class="container-fluid padding-top-70">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row margin-top-120">
            <div class="col-md-12">
              <p class="font-size-42 text-align-center CamptonBold text-transform line-height-50">CONDITIONS GENERALES DE VENTE ET DE PRESTATIONS DE SERVICES</p>
              <p>&nbsp;</p>
            </div>
            <div class="col-md-12">
                <p>
                    <strong>Article 1 – OBJET ET CHAMP D’APPLICATION</strong>
                    <br />
                    Toute commande implique l'adhésion sans réserve du client aux présentes conditions générales qui prévalent sur toutes autres conditions, à l'exception de celles expressément acceptées par SO2TT365. Elles s'appliquent de plein droit à toutes les ventes et prestations de services conclues par SO2TT365 auprès des clients professionnels notamment, la vente du droit d’utilisation de progiciels, l’installation, le paramétrage, la formation des utilisateurs des progiciels.
                </p>
                <p>
                    <strong>Article 2 – MODALITES DES PRESTATIONS DE MISE EN ŒUVRE ET DE SERVICES</strong>
                    <br />
                    Préalablement à la réalisation des prestations de mise en œuvre concernant le progiciel (analyse, installation, paramétrage, formation, reprise de données, développement spécifique de programmes, réalisation d’interfaces avec d’autres logiciels…), le client prendra toutes les mesures nécessaires pour la protection de ses équipements informatiques, notamment contre les virus et autres procédés hostiles d’intrusion. Le client s’engage à donner à SO2TT365 accès à toutes les informations jugées nécessaires par cette dernière pour assurer les prestations de mise en œuvre.
                    Le contenu, les modalités et les conditions d’exécution des prestations de services seront détaillés dans la proposition commerciale établie par SO2TT365 et acceptée par le client préalablement à toute intervention.
                </p>
                <p>
                    <strong>Article 3– COMMANDES</strong>
                    <br />
                    Les ventes de produits et de prestations ne sont parfaites qu’après acceptation par le client de la proposition commerciale élaborée par SO2TT365 sur la base des besoins exprimés par le client, matérialisée par la mention « Bon pour accord » et la signature. Les éventuelles modifications de la commande demandées par le client ne seront prises en compte, dans la limite des possibilités de SO2TT365 et à sa seule discrétion, qu’après signature d’un bon de commande spécifique et ajustement éventuel du prix.
                    En cas d'annulation de la commande par le client après son acceptation, pour quelque raison que ce soit, hormis la force majeure, l'éventuel acompte versé à la commande sera de plein droit acquis à SO2TT365 et ne pourra donner lieu à un quelconque remboursement. 
                </p>
                <p>
                    <strong>Article 4 – TARIFS</strong>
                    <br />
                    Les produits et services sont fournis aux tarifs figurant dans la proposition commerciale adressée au client et acceptée par lui. Ces tarifs sont fermes et non révisables pendant leur période de validité. Ces prix sont nets et hors taxes. Les frais de déplacement font l’objet d’une facturation spécifique. Ces prix ne comprennent pas le transport et les assurances qui restent à la charge du client.
                </p>
                <p>
                    <strong>Article 5 – CONDITIONS DE REGLEMENT</strong>
                    <br />
                    Un acompte, correspondant à 50% du prix total T.T.C. des produits et/ou services commandés, sera exigé par SO2TT365 lors de la passation de la commande. Sauf mention dérogatoire expresse et préalable, le solde du prix est payable au jour de l’installation des produits chez le client quelle que soit la date prévisionnelle des prestations complémentaires. Les commandes de produits et/ou services d’un montant T.T.C. inférieur à 500 euros donneront lieu à un règlement comptant au jour de la vente ou de la réalisation de la prestation. Les règlements pourront intervenir par prélèvement automatique.
                </p>
                <p>
                    <strong>Article 6– RESERVE DE PROPRIETE</strong>
                    <br />
                    En cas de vente de matériels, SO2TT365 se réserve, jusqu’au complet paiement du prix par le client, la propriété des produits vendus. Les produits ne peuvent donc être ni revendus, ni transformés avant ce paiement. Le client s’engage à s’assurer contre tous les risques que comporte la détention de ces produits jusqu’à leur parfait paiement. Le risque de perte et de détérioration sera transféré au client dès la livraison des produits commandés.
                </p>
                <p>
                    <strong>Article 7 – LIVRAISONS/ INTERVENTIONS</strong>
                    <br />
                    Les produits et prestations commandés par le client seront livrés/exécutés dans le délai figurant sur la proposition commerciale établie par SO2TT365. Les délais de livraison et d’intervention ne sont donnés qu’à titre informatif et indicatif. Tout retard de livraison ou d’intervention ne peut donner lieu à aucune pénalité ou indemnité, ni motiver l’annulation de la commande ou la résiliation de la vente.
                </p>
                <p>
                    <strong>Article 8 – CONSERVATION DES DONNEES INFORMATIQUES</strong>
                    <br />
                    Le client est informé qu’il lui appartient de procéder aux démarches, déclarations, demandes d’autorisation prévues par les lois et règlements en vigueur concernant tout traitement qu’il effectue et données qu’il traite à partir des progiciels 
                </p>
                <p>
                    <strong>Article 9 – RESPONSABILITE - GARANTIE</strong>
                    <br />
                    SO2TT365 s’engage à apporter tout le soin possible à l’exécution de ses obligations. Elle est toutefois soumise à une obligation de moyens au titre des présentes. Les produits distribués SO2TT365 sont conformes au descriptif de l’éditeur et garantis par celui-ci.
                    Le progiciel sera utilisé par le client sous ses seuls contrôles, direction et sous sa seule responsabilité. Par conséquent, relèvent de la responsabilité exclusive du client : la mise en œuvre de tous procédés et mesures utiles destinés à protéger ses matériels, progiciels, logiciels, mots de passe, et à se prémunir contre tous virus et intrusions ; le respect des préconisations techniques fournies par l’éditeur afin d’éviter les ralentissements, blocages, altérations de données… ; le choix du fournisseur d’accès ou du support de télécommunication ; les erreurs commises par son personnel dans l’utilisation du progiciel.
                    En aucun cas, SO2TT365 ne pourra être déclarée responsable des dommages pouvant survenir d’une mauvaise utilisation du progiciel par le client et notamment de la destruction de fichiers ou de programmes lors de ses interventions, le client devant disposer avant toute intervention d’un double de l’ensemble des informations. En aucun cas,  SO2TT365 ne pourra être tenue pour responsable tant à l’égard du Client qu’à l’égard de tiers, pour tout dommage indirect, qui serait matériel ou immatériel, prévisible ou imprévisible, tel que perte d’exploitation, perte de bénéfice ou d’image ou de toute autre perte financière résultant de l’utilisation ou de l’impossibilité pour le client d’utiliser le progiciel vendu et installé par SO2TT365 ainsi que toute perte ou détérioration d’informations.
                </p>
                <p>
                    <strong>Article 10 – FORCE MAJEURE</strong>
                    <br />
                    La responsabilité de SO2TT365 ne pourra être recherchée au cas où elle ne serait pas en mesure d’exécuter ses obligations contractuelles pour des raisons de force majeure. Sont notamment considérés comme des cas de force majeure les dysfonctionnements des opérateurs télécom et des télécommunications dès lors que ces dysfonctionnements n’ont pas pour origine les moyens techniques mis en œuvre par SO2TT365  et ne relève pas de sa responsabilité.
                </p>
                <p>
                    <strong>Article 11 – SOUS TRAITANCE</strong>
                    <br />
                    Le client autorise expressément la société SO2TT365 à avoir recours, sans formalité préalable, à des partenaires pour tout ou partie de l’exécution des commandes.
                </p>
                <p>
                    <strong>Article 12 – NON-DEBAUCHAGE</strong>
                    <br />
                    Le client s’engage, pendant toute la durée du contrat et 12 mois après la fin de celui-ci, à ne pas démarcher, recruter ni faire travailler, directement ou indirectement, un membre du personnel de la société SO2TT365, sauf autorisation écrite et préalable de cette dernière.
                </p>
                <p>
                    <strong>Article 13 - REFERENCES COMMERCIALES</strong>
                    <br />
                    Le client autorise expressément SO2TT365 à faire figurer son nom et son logo dans ses références commerciales communiquées au public, sur tout support, sauf refus expressément notifié du client.
                </p>
                <p>
                    <strong>Article 14 – CONFIDENTIALITE</strong>
                    <br />
                    Toutes les informations échangées entre SO2TT365 et le client ou dont ils auraient connaissance à l’occasion de la préparation et de l’exécution du contrat, quel que soit leur support, présentent un caractère strictement confidentiel. Chacune des parties s’engage à les protéger et à ne pas les divulguer à des tiers sans l’autorisation préalable et écrite de l’autre partie. Les parties s’engagent à respecter les obligations résultant du présent article pendant toute la durée du contrat et pendant les 5 ans suivant sa cessation.
                </p>
                <p>
                    <strong>Article 15 - DROIT APPLICABLE - ATTRIBUTION DE JURIDICTION</strong>
                    <br />
                    Toute question relative aux présentes conditions générales ainsi qu’aux ventes et prestations de services qu’elles régissent, qui ne serait pas traitée par les présentes stipulations contractuelles, sera régie par la du pays du client.
                    Tous les litiges auxquels le présent contrat pourrait donner lieu, concernant tant sa validité, son interprétation, son exécution, sa résiliation, ses conséquences et ses suites seront portés devant le Tribunal de Commerce du pays du client, à défaut d’accord amiable entre les parties, nonobstant pluralité de défendeurs ou appel en garantie.
                </p>
            </div>
            <div class="col-md-12">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div> 
    </div>
    <Footer></Footer>
</div>
</template>

<script>
export default {
  name: "Conditions-generales",
  mounted () {
    window.scrollTo(0, 0);
    document.title = 'SOFTT365 : Conditions générales';
  },
  components: {},
};
</script>
